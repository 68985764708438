/* BLOG CONSTANTS */
export const BLOG_LIST_REQUEST = 'BLOG_LIST_REQUEST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';
export const BLOG_LIST_FAIL = 'BLOG_LIST_FAIL';

export const BLOG_BY_ID_REQUEST = 'BLOG_BY_ID_REQUEST';
export const BLOG_BY_ID_SUCCESS = 'BLOG_BY_ID_SUCCESS';
export const BLOG_BY_ID_FAIL = 'BLOG_BY_ID_FAIL';

export const BLOG_CREATE_REQUEST = 'BLOG_CREATE_REQUEST';
export const BLOG_CREATE_SUCCESS = 'BLOG_CREATE_SUCCESS';
export const BLOG_CREATE_FAIL = 'BLOG_CREATE_FAIL';

export const BLOG_UPDATE_REQUEST = 'BLOG_UPDATE_REQUEST';
export const BLOG_UPDATE_SUCCESS = 'BLOG_UPDATE_SUCCESS';
export const BLOG_UPDATE_FAIL = 'BLOG_REGISTER_FAIL';

export const BLOG_DELETE_REQUEST = 'BLOG_DELETE_REQUEST';
export const BLOG_DELETE_SUCCESS = 'BLOG_DELETE_SUCCESS';
export const BLOG_DELETE_FAIL = 'BLOG_REGISTER_FAIL';
